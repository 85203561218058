import { TreeLeaf, RteNode } from '~/types/rte/rteTypes'

// .. Mimic how nested  ol structure looks in cms
export const OL_TYPES = ['1', 'a', 'i'] as const

// Method to check if a json RTE field is empty
export const isEmptyJsonRteField = (json: RteNode) => {
  return !json?.children?.some((child: RteNode) => {
    // Elements which might not include text means that rte is not empty
    const elementsWithoutTexts = ['reference', 'img'] as const
    if (elementsWithoutTexts.includes(child?.type)) {
      return true
    }

    // Recursive check to dive tree structure
    if (child?.children) {
      return !isEmptyJsonRteField(child)
    }

    // Any text is means that rte is not empty
    if ((child as TreeLeaf)?.text?.length > 0) {
      return true
    }

    return false
  })
}

export const hasDocEmptyTexts = (obj: any) => {
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      if (typeof obj[key] === 'object' && obj[key] !== null) {
        if (!hasDocEmptyTexts(obj[key])) {
          return false
        }
      } else if (key === 'text' && obj[key] !== '') {
        return false
      }
    }
  }
  return true
}

export const applyOlNestingType = (node: RteNode, prevOlType?: (typeof OL_TYPES)[number]) => {
  let validOlType = prevOlType

  // .. Apply type to <ol> elements
  if (node.type === 'ol') {
    // .. Default type is '1'. Otherwise we increase the type by one
    validOlType = (prevOlType && OL_TYPES?.[OL_TYPES.indexOf(prevOlType) + 1]) || OL_TYPES[0]
    node.attrs = { ...node.attrs, type: validOlType }
  }

  // .. Recursion for all children
  if (node?.children) {
    return { ...node, children: node.children.map((child: RteNode) => applyOlNestingType(child, validOlType)) }
  }

  return node
}
