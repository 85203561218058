export default function (to, _from, savedPosition) {
  if (savedPosition) {
    return savedPosition
  }
  if (to.hash) {
    return {
      selector: to.hash,
      offset: { x: 0, y: 205 },
      behavior: 'smooth',
    }
  }
  if (!to.query.noscroll) {
    return { x: 0, y: 0 }
  }
}
