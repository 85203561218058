





















































import { defineComponent, useRoute, onMounted, ref, useContext } from '@nuxtjs/composition-api'

import Topbar from '@/components/global/topbar/Topbar.vue'
import Navigation from '@/components/global/navigation/Navigation.vue'

import { useSidebarStore } from '@/store'

export default defineComponent({
  components: {
    Topbar,
    Navigation,
  },
  setup() {
    const route = useRoute()
    const showBannerAndNavbar = ref(true)
    const showSearch = ref(true)
    const showSearchOverlay = ref(false)
    const { sidebar } = useSidebarStore()
    const context = useContext()
    const isPreviewServer = context.$config.cst.isPreview

    const onScrollHideBanner = () => {
      if (window.innerWidth < 1024) {
        if (window.pageYOffset > 102) {
          showBannerAndNavbar.value = false
        } else if (window.pageYOffset < 67 && showBannerAndNavbar.value === false) {
          showBannerAndNavbar.value = true
        }
      } else {
        if (window.pageYOffset > 114) {
          showBannerAndNavbar.value = false
        }
        if (window.pageYOffset < 115 && showBannerAndNavbar.value === false) {
          showBannerAndNavbar.value = true
        }
      }
    }

    const onResizeShowSearch = () => {
      // show search bar on desktope screen if it was hidden by onToggleSearch on mobile/tabled screen
      if (showSearch.value === false && window.innerWidth > 1023) {
        showSearch.value = true
      }
    }

    const onToggleSearch = () => {
      showSearch.value = !showSearch.value
    }

    const onNavigationHover = (hoverIn: boolean) => {
      showSearchOverlay.value = hoverIn
    }

    onMounted(() => {
      // set event listeners
      window.addEventListener('scroll', onScrollHideBanner)
      window.addEventListener('resize', onResizeShowSearch)
    })

    return {
      route,
      sidebar,
      showBannerAndNavbar,
      showSearch,
      onScrollHideBanner,
      onResizeShowSearch,
      onToggleSearch,
      onNavigationHover,
      isPreviewServer,
      showSearchOverlay,
    }
  },
  head: {},
})
