


















import { defineComponent, useContext } from '@nuxtjs/composition-api'
import { useProductStore } from '~/store/product'

export default defineComponent({
  setup() {
    const { $config } = useContext()
    const renderTarget = process.static ? 'STATIC' : 'SSR'
    const renderTimestamp = new Date()
    const renderUTCTimestamp = renderTimestamp.toLocaleString('en-US', { timeZone: 'UTC', timeZoneName: 'short' })
    const renderESTTimestamp = renderTimestamp.toLocaleString('en-US', {
      timeZone: 'America/New_York',
      timeZoneName: 'short',
    })
    const commit = $config.commitSha

    const productStore = useProductStore()
    const currentProduct = productStore.currentProduct

    return {
      debugInfo: {
        renderTarget,
        renderUTCTimestamp,
        renderESTTimestamp,
        commit,
        currentProduct,
      },
    }
  },
})
